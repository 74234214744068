<template>
  <div
    class="
      d-flex
      flex-lg-row flex-md-row flex-sm-column flex-column
      justify-content-center
    "
  >
    <!--begin::Card-->
    <EmployeeVerificationEmployeeInformation></EmployeeVerificationEmployeeInformation>
    <transition name="slide-fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import EmployeeVerificationEmployeeInformation from "@/modules/auth/components/verification/EmployeeVerificationEmployeeInformation";
import { mapGetters } from "vuex";
export default {
  name: "EmployeeVerificationPage",
  components: { EmployeeVerificationEmployeeInformation },
  beforeMount() {
    if (this.isLoggedIn) {
      const code = this.$route.query.code;
      this.$router.push("/verification/employee/login?code=" + code);
    }
  },
  mounted() {
    const code = this.$route.query.code;
    console.log("Code:");
    this.$store.dispatch("assignVerificationCode", code);
    this.$store.dispatch("getEmployeeVerification", code);
    this.$store.dispatch("fetchUserOrganisation").then((response) => {
      console.log("User Org", response.data);
      if (response.data.company) {
        console.log("Employee have company");
        this.$router.push("/verification/employee/unqualified?code=" + code);
      }
    });
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "isAuthenticated",
    }),
  },
};
</script>

<style scoped></style>
