<template>
  <b-card class="card-custom m-5">
    <b-card-title class="card-title">
      <h3 class="card-label font-weight-bolder text-dark">
        Employee Information
      </h3>
    </b-card-title>

    <div class="d-flex mt-5 pt-5 flex-column">
      <div class="mx-auto mb-5 svg-icon svg-icon-light-primary svg-icon-5x">
        <inline-svg src="/media/svg/icons/General/User.svg" />
      </div>

      <b-skeleton-wrapper :loading="isBusy" class="w-lg-300px">
        <template #loading>
          <b-card class="border-0">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-card>

          <b-card class="border-0">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-card>

          <b-card class="border-0">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-card>
        </template>
      </b-skeleton-wrapper>

      <div
        class="d-flex flex-column mt-3 p-5 align-items-center mb-2"
        v-if="verify"
      >
        <span class="h5 font-weight-bolder"
          >Please verify the following information is yours and correct.</span
        >
      </div>

      <div
        class="
          d-flex
          mt-3
          align-items-center
          mb-5
          bg-light
          border border-light
          rounded
          px-3
          py-3
        "
        v-if="verify"
      >
        <div
          class="symbol symbol-40 symbol-light-primary mr-5 align-self-start"
        >
          <span class="symbol-label">
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <inline-svg src="/media/svg/icons/General/User.svg" />
            </span>
          </span>
        </div>

        <!--begin::Text-->
        <div class="d-flex flex-column">
          <!--begin::Symbol-->

          <!--end::Symbol-->
          <h5 class="font-weight-bolder">Personal</h5>
          <span class="">
            <strong>First Name: </strong> {{ verify.employee.first_name }}</span
          >
          <span class="mt-1"
            ><strong>Last Name: </strong> {{ verify.employee.last_name }}</span
          >
        </div>
        <!--end::Text-->
      </div>

      <div
        class="
          d-flex
          align-items-center
          bg-light
          border-top border border-light
          rounded
          px-3
          py-3
        "
        v-if="verify"
      >
        <div
          class="symbol symbol-40 symbol-light-primary mr-5 align-self-start"
        >
          <span class="symbol-label">
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <inline-svg src="/media/svg/icons/Home/Building.svg" />
            </span>
          </span>
        </div>

        <!--begin::Text-->
        <div class="d-inline-flex flex-column">
          <!--begin::Symbol-->

          <!--end::Symbol-->
          <h5 class="font-weight-bolder">Employment</h5>
          <div class="d-flex flex-grow-1 justify-content-center">
            <div class="d-flex flex-column mr-5">
              <span class="">
                <strong>Email </strong> <br />
                {{ verify.employee.email }}</span
              >
              <span class="mt-1"
                ><strong>Unique Code </strong> <br />
                {{ verify.employee.unique_code }}</span
              >
            </div>
            <div class="d-flex flex-column">
              <span class="">
                <strong>Company </strong> <br />
                {{ verify.organisation.name }}</span
              >
              <span class="mt-1"
                ><strong>Reg. Code </strong> <br />
                {{ verify.organisation.registration_code }}</span
              >
            </div>
          </div>
        </div>
        <!--end::Text-->
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EmployeeVerificationEmployeeInformation",
  computed: {
    ...mapState({
      verify: (state) =>
        state.AuthModule.EmployeeVerification.VerificationInit.verify,
      isBusy: (state) =>
        state.AuthModule.EmployeeVerification.VerificationInit.busy,
    }),
  },
};
</script>

<style scoped></style>
